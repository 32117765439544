import React from "react"
import Seo from "../components/seo"
import Layout from "../components/layout"
import { Button } from "../components/button"

const SuccessPage = (props) => {
    return (
        <Layout>
            <Seo
                title="Form Submission Successful! Transforming Homes: Kitchen/Bathroom Remodeling, Siding, Windows & Doors, Decks & Patios, Interior Design"
                description="Thank you for submitting the form! Expect exceptional service for kitchen remodeling, bathroom remodeling, siding, windows & doors, decks & patios, and interior design projects. We'll be in touch soon to discuss your home transformation."
                location={props.location.pathname}
                keywords={['Kaylar Construction']}
            />
            <div className="pt-64 pb-40 px-5 lg:px-10">
                <div className="container mx-auto text-center">
                    <div className="max-w-lg mx-auto">
                        <h3 className="font-bold text-2xl lg:text-3xl pb-3">Submission Successful!</h3>
                        <p className="pb-5">
                            Our team has received your message and will get back to you ASAP. Thank you for your interest in our services!
                        </p>
                        <div className="pb-5">
                            <Button buttonText='View All Services' buttonPage='/#services' />
                        </div>
                        <Button buttonText='Home Page' buttonPage='/' />
                    </div>
                </div>
            </div>
        </Layout>
    )
}
export default SuccessPage