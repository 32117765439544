import React, { Component } from "react"
import { Link } from "gatsby"

// TO USE: takes in two props: 1) buttonPage - page name to link to and 2) buttonText - text inside button
export class Button extends Component {
    render() {
        return (
            <div>
                <Link to={this.props.buttonPage}>
                    <button className={`relative h-10 py-2 px-5 transition-colors rounded-lg border focus:shadow-outline hover:shadow-lg font-semibold transition duration-300 ease-in-out text-sm ${this.props.lightBlue ? 'border-lightblue bg-lightblue text-white hover:bg-darkblue hover:text-white' : this.props.headerLightBlue ? 'border-lightblue bg-lightblue text-white hover:bg-white hover:text-black' :  'border-darkblue bg-darkblue text-white hover:bg-lightblue hover:text-white'}`} title={this.props.buttonText} alt={this.props.buttonText}>
                        <span>{this.props.buttonText}</span>
                    </button>
                </Link>
            </div>
        )
    }
}